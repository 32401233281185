import 'highlight.js/styles/atom-one-dark.css'; // github样式文件
import '@/styles/global.css';

import React from 'react';

import { Layout, RootElement } from '@/components';

const pagesWithoutLayout = ['/login', '/dashboard/login'];

export const wrapRootElement = ({ element }: { element: JSX.Element }) => {
  return <RootElement>{element}</RootElement>;
};

// Wraps every page in a component
export const wrapPageElement = ({
  element,
  props
}: {
  element: JSX.Element;
  props: Record<string, unknown>;
}) => {
  if (props.location && pagesWithoutLayout.includes(props.location.pathname)) {
    return element;
  }
  return <Layout {...props}>{element}</Layout>;
};
