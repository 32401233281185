/*
 * @Description:
 * @Author: didadida262
 * @Date: 2024-05-10 11:26:02
 * @LastEditors: didadida262
 * @LastEditTime: 2024-08-21 11:08:25
 */
import cn from 'classnames';
import React, { MouseEvent, ReactNode, useMemo, useState } from 'react';

import LoadingSvg from '@/assets/images/common/loading.inline.svg';
import { useTranslation } from '@/i18n';

export enum EButtonType {
  PRIMARY = 'PRIMARY',
  GHOST = 'GHOST',
  BLANK = 'BLANK'
}
interface IButtonProps {
  className?: string;
  children: ReactNode;
  type?: EButtonType;
  title?: string;
  loading?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disable?: boolean;
  buttonType?: 'submit' | 'reset' | 'button';
}
export function Button(props: IButtonProps) {
  const {
    className,
    children,
    type = EButtonType.PRIMARY,
    loading = false,
    title,
    onClick,
    buttonType,
    disable = false
  } = props;
  const [cls, bpopbg] = useMemo(() => {
    const resultArray = [
      'rounded-[4px] flex items-center justify-center focus:outline-none'
    ];
    const popBg = ['border-t-[#333c4a]'];
    switch (type) {
      case EButtonType.PRIMARY:
        resultArray.push('bg-bgFourthColor bg-btnPrimaryHover');
        break;
      case EButtonType.GHOST:
        resultArray.push('bg-[#475062] hover:opacity-75');
        popBg.push('border-t-[#475062]');
        break;
      case EButtonType.BLANK:
        resultArray.push(
          'bg-[#21222D] border-[#3272C7] border-[1px]  text-[#3272C7] hover:opacity-75'
        );
        popBg.push('border-t-[#21222D]');
        break;
      default:
        break;
    }
    return [resultArray.join(' '), popBg.join(' ')];
  }, [type]);
  const { t } = useTranslation();

  const [isHover, setIsHover] = useState<boolean>(false);
  return (
    <div className="relative w-full flex-1">
      <button
        type={buttonType}
        onClick={(e) => {
          if (!loading && onClick && !disable) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onClick(e as any);
          }
        }}
        onMouseMove={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        className={cn(
          cls,
          className,
          'fontBtn',
          'bg-bgCardBtnColor',
          'whitespace-nowrap',
          disable ? 'cursor-not-allowed opacity-75' : 'opacity-100'
        )}
      >
        {children}
        {loading && <LoadingSvg className="ml-2 animate-spin" width={24} />}
      </button>
      {isHover && title && (
        <div className="absolute right-[50%] -top-[30px] z-[200] translate-x-[50%] whitespace-nowrap rounded-[4px] bg-[#333c4a] px-3 py-1">
          <div
            className={cn(
              bpopbg,
              'absolute right-[50%] bottom-0 h-0 w-0 translate-y-[100%] border-[5px] border-solid border-x-transparent  border-b-transparent opacity-75'
            )}
          ></div>
          {t(title)}
        </div>
      )}
    </div>
  );
}
