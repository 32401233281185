import { Emitter } from '@white-matrix/event-emitter';

import { Event } from '@white-matrix/event-emitter';
import request, { postWithJson } from '../request';
import { GITHUB_APP_CLIENT_ID } from 'gatsby-env-variables';
import { IUserInfo } from '@/utils/tokenUtils';


export enum UserDetailChange {
  UPDATE
}
interface UserDetailUpdateAction {
  type: UserDetailChange;
  data: IUserInfo;
}

class AccountService {
  readonly _userDetailDidChange = new Emitter<UserDetailUpdateAction>();
  readonly onUserDetailChange: Event<UserDetailUpdateAction>;

  constructor() {
    this.onUserDetailChange = this._userDetailDidChange.event;
  }

  async githubOAuthCodeLogin(code: string) {
    return await postWithJson('/api/v1/user/github/login', {
      clientId: GITHUB_APP_CLIENT_ID,
      code
    });
  }

  async passwordLogin(userId: string, password: string) {
    return await postWithJson('/api/v1/user/password/login', {
      userId,
      password
    });
  }

  async getUserInfo() {
    return await request.get('/api/v1/user/info');
  }

  async refreshToken(refreshToken: string) {
    return await postWithJson('/api/v1/user/refresh', {
      refreshToken
    });
  }

  async getStagingEnvSaveFlag() {
    return await request('/api/v1/user/reserve');
  }

  async setStagingEnvSaveFlag() {
    return await request.post('/api/v1/user/reserve');
  }

  updateUserDetailTrigger(user: IUserInfo) {
    this._userDetailDidChange.fire({
      type: UserDetailChange.UPDATE,
      data: user
    });
  }
}

export const accountService = new AccountService();
