import './styles.css';

import cn from 'classnames';
import { navigate } from 'gatsby';
import React, { ReactNode } from 'react';
import { useAsyncFn } from 'react-use';

// import { Button, EButtonType } from '@/components';
import MethodFileSVG from '@/assets/images/project/method_file.inline.svg';
import MethodGithubSVG from '@/assets/images/project/method_github.inline.svg';
import { ButtonCommon, EButtonType } from '@/components/common/ButtonCommon';
import { CreateProjectCard } from '@/components/common/CreateProjectCard';
import { useTranslation } from '@/i18n';
import { projectService } from '@/services/projectService';
import templateService from '@/services/templateService';
import { Locale } from '@/services/templateService/ITemplateService';
import pattern from '@/styles/pattern';
import { EChainType } from '@/utils/const';
import { copywriter } from '@/utils/copywriter';
import customToast from '@/utils/customToast';
import { triggerGAEvent } from '@/utils/ga';
import { useGetDeviceId } from '@/utils/hooks/useGetDeviceId';

const config = copywriter.projects;

export enum ECreateProjectMethodType {
  BASIC_TEMPLATE = 'start_with_basic_configuration',
  PUBLIC_TEMPLATE = 'choose_public_template',
  PRIVATE_TEMPLATE = 'choose_private_template',
  IMPORT_FROM_GITHUB = 'Github',
  IMPORT_FROM_GIST = 'Gist',
  IMPORT_FROM_FILE = 'file',
  DEFAULT = 'Default'
}

interface ICreateMethodSelectorProps {
  animationKey: number;
  chain: EChainType;
  methodType: ECreateProjectMethodType;
  setMethodType: (type: ECreateProjectMethodType) => void;
  setAnimationKey: React.Dispatch<React.SetStateAction<number>>;
}
export function CreateMethodSelector(props: ICreateMethodSelectorProps) {
  const { methodType, setMethodType, animationKey, setAnimationKey, chain } =
    props;
  const { deviceId } = useGetDeviceId();
  const { i18n, t } = useTranslation();

  const [createProjectByBasicTemplateStatus, doCreateProjectByBasicTemplate] =
    useAsyncFn(async () => {
      setAnimationKey(animationKey + 1);
      try {
        const templateList = await templateService.getTemplates(
          i18n.language === 'en-US' ? Locale.EN : Locale.ZH,
          true,
          !chain || chain === 'all' ? undefined : chain
        );
        const defaultTemplate = templateList.find((item) => item.isDefault);
        if (!defaultTemplate) {
          customToast.error(t('get_default_template_error'));
          return;
        }

        const projectId = await projectService.createProjectByTemplate(
          deviceId,
          chain,
          defaultTemplate.id
        );
        triggerGAEvent('chain_project_create', {
          currentChainID: chain
        });
        location.href = `${location.origin}/workspace/?id=${projectId}&chain=${chain}`;
      } catch (e) {
        customToast.error(t('create_project_error'));
        console.log({ e });
      }
    }, [animationKey, chain, deviceId, i18n.language, setAnimationKey, t]);
  const handleClickCreateProject = (val: number) => {
    switch (val) {
      case 0:
        void doCreateProjectByBasicTemplate();
        break;
      case 1:
        setAnimationKey(animationKey + 1);
        void navigate(`/templates/?chain=${chain}`);
        break;
      case 2:
        setMethodType(ECreateProjectMethodType.PRIVATE_TEMPLATE);
        setAnimationKey(animationKey + 1);
        break;
    }
  };

  return (
    <div className="">
      <div className="flex flex-col gap-y-4">
        <div className={`${pattern.flexbet}`}>
          {config.CreateProjectList.map((item, index) => (
            <div key={item.title}>
              <CreateProjectCard
                title={item.title}
                descripttion={item.description}
                index={index}
                onClick={(val) => {
                  handleClickCreateProject(val);
                }}
              />
            </div>
          ))}
        </div>
        {/* <div className={`markBorderG`}>
          <div
            className={cn(
              'basicTemplate mt-5 mb-4 h-auto rounded-[2px] border-[1px] border-bgProjectCardColor bg-bgProjectCardColor hover:border-borderHoverCardColor hover:bg-bgHoverCardColor sm:py-3'
            )}
          >
            <div className="w-full  px-5 pb-2 text-start font-IBMPlexSans">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-2">
                  <MethodBlankSVG />
                  <span>{i18n.t(ECreateProjectMethodType.BASIC_TEMPLATE)}</span>
                </div>

                <Button
                  className="basicButton float-right !rounded-[2px] border-[1px] bg-[#3272C7] py-1 px-8 text-[#ffffff] opacity-0"
                  loading={createProjectByBasicTemplateStatus.loading}
                  onClick={doCreateProjectByBasicTemplate}
                >
                  {i18n.t('start_button_text')}
                </Button>
              </div>

              <div className="mt-3 whitespace-normal pl-1 text-textFifthSize text-[#95A0AB]">
                {i18n.t('start_with_basic_configuration_des')}
              </div>
            </div>
          </div>
          <div
            className={cn(
              'publicTemplate mt-5 h-auto rounded-[2px] border-[1px] border-bgProjectCardColor bg-bgProjectCardColor hover:border-borderHoverCardColor hover:bg-bgHoverCardColor sm:py-3'
            )}
          >
            <div className="w-full px-5 pb-2 text-start font-IBMPlexSans">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-2">
                  <MethodPublicTemplateSVG />
                  <span>
                    {i18n.t(ECreateProjectMethodType.PUBLIC_TEMPLATE)}
                  </span>
                </div>

                <Button
                  className="basicButton float-right !rounded-[2px] border-[1px] bg-[#3272C7] py-1 px-8 text-[#ffffff] opacity-0"
                  onClick={() => {
                    setAnimationKey(animationKey + 1);
                    void navigate(`/templates/?chain=${chain}`);
                  }}
                >
                  {i18n.t('choose_button_text')}
                </Button>
              </div>

              <div className="mt-3 whitespace-normal pl-1 text-textFifthSize text-[#95A0AB]">
                {i18n.t('choose_public_template_des')}
              </div>
            </div>
          </div>
          <div
            className={cn(
              'privateTemplate mt-5 h-auto rounded-[2px] border-[1px] border-bgProjectCardColor bg-bgProjectCardColor hover:border-borderHoverCardColor hover:bg-bgHoverCardColor sm:py-3'
            )}
          >
            <div className="w-full px-5 pb-2 text-start font-IBMPlexSans">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-x-2">
                  <MethodPrivateTemplateSVG />
                  <span>
                    {i18n.t(ECreateProjectMethodType.PRIVATE_TEMPLATE)}
                  </span>
                </div>

                <Button
                  className="basicButton float-right !rounded-[2px] border-[1px] bg-[#3272C7] py-1 px-8 text-[#ffffff] opacity-0"
                  onClick={() => {
                    setMethodType(ECreateProjectMethodType.PRIVATE_TEMPLATE);
                    setAnimationKey(animationKey + 1);
                  }}
                >
                  {i18n.t('choose_button_text')}
                </Button>
              </div>

              <div className="mt-3 w-full whitespace-normal pl-1 text-textFifthSize text-[#95A0AB]">
                {i18n.t('choose_private_template_des')}
              </div>
            </div>
          </div>
        </div> */}
        <div className="mt-[10px] flex w-full items-center gap-x-[10px]">
          <div className="h-[1px] w-[50%] border-t-[2px] border-dashed border-bgProjectCardColor" />
          <span className="justify-center whitespace-nowrap text-center">
            {i18n.t('or_import')}
          </span>
          <div className="h-[1px] w-[50%] border-t-[2px] border-dashed border-bgProjectCardColor" />
        </div>
        <div className=" flex w-full flex-col gap-4 sm:flex-row">
          <MethodButton
            onClick={() => {
              setMethodType(ECreateProjectMethodType.IMPORT_FROM_GITHUB);
              setAnimationKey(animationKey + 1);
            }}
            isActive={
              methodType === ECreateProjectMethodType.IMPORT_FROM_GITHUB
            }
            className={cn(
              'border-[1px] border-borderUlColor  bg-bgProjectCardColor hover:bg-white-5 sm:py-5'
            )}
          >
            <div className="flex items-center gap-x-2 font-medium">
              <MethodGithubSVG />
              <span>{ECreateProjectMethodType.IMPORT_FROM_GITHUB}</span>
            </div>
          </MethodButton>
          <MethodButton
            onClick={() => {
              setMethodType(ECreateProjectMethodType.IMPORT_FROM_GIST);
              setAnimationKey(animationKey + 1);
            }}
            isActive={methodType === ECreateProjectMethodType.IMPORT_FROM_GIST}
            className={cn(
              'border-[1px] border-borderUlColor bg-bgProjectCardColor hover:bg-white-5 sm:py-5'
            )}
          >
            <div className="flex items-center gap-x-2 font-medium">
              <MethodGithubSVG />
              <span>{ECreateProjectMethodType.IMPORT_FROM_GIST}</span>
            </div>
          </MethodButton>
          <MethodButton
            onClick={() => {
              setMethodType(ECreateProjectMethodType.IMPORT_FROM_FILE);
              setAnimationKey(animationKey + 1);
            }}
            isActive={methodType === ECreateProjectMethodType.IMPORT_FROM_FILE}
            className={cn(
              'border-[1px] border-borderUlColor bg-bgProjectCardColor hover:bg-white-5 sm:py-5'
            )}
          >
            <div className="flex items-center gap-x-2 font-medium">
              <MethodFileSVG />
              <span>{i18n.t(ECreateProjectMethodType.IMPORT_FROM_FILE)}</span>
            </div>
          </MethodButton>
        </div>
      </div>
    </div>
  );
}

interface IMethodButtonProps {
  className?: string;
  isActive: boolean;
  children: ReactNode;
  onClick?: () => void;
  loading?: boolean;
}
function MethodButton(props: IMethodButtonProps) {
  const { className, isActive, children, onClick, loading = false } = props;
  return (
    <ButtonCommon
      onClick={onClick}
      loading={loading}
      type={EButtonType.SIMPLE}
      className={cn(
        'bg-btnThirdHover h-10 w-full cursor-pointer hover:!opacity-90',
        className
      )}
    >
      {children}
    </ButtonCommon>
  );
}
