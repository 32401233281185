/*
 * @Description:
 * @Author: didadida262
 * @Date: 2024-05-10 11:26:02
 * @LastEditors: didadida262
 * @LastEditTime: 2024-08-23 14:32:59
 */
import store from 'store2';

export const CHAIN_IDE_DASHBOARD_PREFIX = 'chain-ide-';

export class Storage {
  /**
   * 获取存储值
   * @param key 存储 key
   * @returns 值，set的时候是什么类型，get 就是什么类型
   */
  get<T = string>(key: string) {
    return store.get(`${CHAIN_IDE_DASHBOARD_PREFIX}${key}`) as T;
  }

  /**
   * 设置存储
   * @param key 存储 key
   * @param data 存储值，可以是任何类型，不需要 pre covert to string
   */
  set(key: string, data: unknown) {
    store.set(`${CHAIN_IDE_DASHBOARD_PREFIX}${key}`, data);
  }

  remove(key: string) {
    store.remove(`${CHAIN_IDE_DASHBOARD_PREFIX}${key}`);
  }

  batchRemove(keys: string[]) {
    for (const key of keys) {
      store.remove(`${CHAIN_IDE_DASHBOARD_PREFIX}${key}`);
    }
  }
}

export const storageUtil = new Storage();

export enum EStorageKeys {
  'ACCESS_TOKEN' = 'ACCESS_TOKEN',
  'ACCESS_TOKEN_EXPIRE_TIME' = 'ACCESS_TOKEN_EXPIRE_TIME',
  'REFRESH_TOKEN' = 'REFRESH_TOKEN',
  'REFRESH_TOKEN_EXPIRE_TIME' = 'REFRESH_TOKEN_EXPIRE_TIME',
  'USER_INFO' = 'USER_INFO',
  'THEME_MODE' = 'THEME_MODE',

  'TEMPLATE_LIST_SCROLL_Y' = 'template_list_scroll_y'
}
// // project
// 'Project_Is_In_Manager' = 'project-is-in-manager',
// 'Project_Chain_Type' = 'project-chain-type',
// 'Project_Is_Running' = 'project-is-running'
