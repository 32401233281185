/*
 * @Description:
 * @Author: didadida262
 * @Date: 2024-05-10 11:26:02
 * @LastEditors: didadida262
 * @LastEditTime: 2024-07-11 18:09:46
 */
import cn from 'classnames';
import React from 'react';

import CheckSVG from '@/assets/images/project/checked.inline.svg';
import UncheckSVG from '@/assets/images/project/unchecked.inline.svg';

interface ICheckerProps {
  isChecked: boolean;
  handelClick: () => void;
  label?: string;
  className?: string;
}

export function Checker(props: ICheckerProps) {
  const { isChecked, handelClick, label, className } = props;
  return (
    <div
      className={cn(
        className,
        ' flex w-full cursor-pointer items-center gap-x-2 text-themeColor duration-300 hover:text-themeColorHeavy'
      )}
      onClick={handelClick}
    >
      {isChecked ? <CheckSVG /> : <UncheckSVG />}
      <span className="shrink-0 text-textTertiaryColor line-clamp-1">
        {label}
      </span>
    </div>
  );
}
