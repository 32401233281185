import { useContext, useEffect, useState } from 'react';

import { UserContext } from '@/libs/userClient/userProvider';
import request from '@/services/request';
import { getDeviceId } from '@/utils/hooks/useGetDeviceId';

export function useGetUserHash() {
  const deviceId = getDeviceId();
  const { userInfo } = useContext(UserContext);
  const [userHash, setUserHash] = useState('');

  useEffect(() => {
    if (deviceId) {
      void request(
        `/api/v1/user/intercom/hash?deviceId=${userInfo?.userId || deviceId}`
      )
        .then((res: string) => {
          setUserHash(res || '');
        })
        .catch((e: Error) => {
          console.error(e.message);
        });
    }
  }, [deviceId, userInfo]);

  return { userHash };
}
