/* eslint-disable prettier/prettier */
import { navigate } from 'gatsby';
import React, { ReactNode, useContext, useEffect, useState } from 'react';

import { UserContext } from '@/libs/userClient/userProvider';
import { EStorageKeys, storageUtil } from '@/utils/storage';

interface IProps {
  children: ReactNode;
}

const AuthRoute = (props: IProps) => {
  const { children } = props;
  const { userInfo } = useContext(UserContext);
  const [userInfoFlag, setuserInfoFlag] = useState({ ...userInfo });

  const storageInfo = storageUtil.get(EStorageKeys.ACCESS_TOKEN);
  useEffect(() => {
    setuserInfoFlag(userInfo);
  }, [userInfo]);

  if (!storageInfo) {
    void navigate('/login');
    return null;
  }

  if (storageInfo || userInfoFlag.userId) {
    return <>{children}</>;
  } else {
    void navigate('/login');
    return null;
  }
};

export default AuthRoute;
