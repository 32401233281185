/*
 * @Description:
 * @Author: didadida262
 * @Date: 2024-05-09 13:49:02
 * @LastEditors: didadida262
 * @LastEditTime: 2024-05-09 14:53:55
 */
import React, { useMemo } from 'react';

import ChainAllSVG from '@/assets/images/common/chain_all.svg';
import ChainAntSVG from '@/assets/images/common/chain_ant.svg';
import ChainAptosSVG from '@/assets/images/common/chain_aptos.svg';
import ChainAtrimSVG from '@/assets/images/common/chain_arbitrum.svg';
import ChainAstarSVG from '@/assets/images/common/chain_astar.png';
import ChainBnbSVG from '@/assets/images/common/chain_bnb.svg';
import ChainChain33SVG from '@/assets/images/common/chain_chain33.png';
import ChainConfluxSVG from '@/assets/images/common/chain_conflux.png';
import ChainDfinitySVG from '@/assets/images/common/chain_dfinity.png';
import ChainEthSVG from '@/assets/images/common/chain_eth.png';
import ChainFiscoSVG from '@/assets/images/common/chain_fisco.svg';
import ChainFlowSVG from '@/assets/images/common/chain_flow.svg';
import ChainIrisSVG from '@/assets/images/common/chain_iris.png';
import ChainNervosSVG from '@/assets/images/common/chain_nervos.svg';
import ChainPolygonSVG from '@/assets/images/common/chain_polygon.png';
import ChainSuiSVG from '@/assets/images/common/chain_sui.svg';
import ChainXdcSVG from '@/assets/images/common/chain_xdc.svg';
import { EChainType } from '@/utils/const';
interface IChainIconProps {
  chain: EChainType | string;
  className?: string;
}

export function ChainIcon(props: IChainIconProps) {
  const { chain, className } = props;

  const icon = useMemo(() => {
    switch (chain) {
      case EChainType.All:
        return <img src={ChainAllSVG} className={className} />;
      case EChainType.Arbitrum:
        return (
          <img
            src={ChainAtrimSVG}
            className={className}
            width="18px"
            height="18px"
          />
        );
      case EChainType.Ethereum:
        return <img src={ChainEthSVG} className={className} />;
      case EChainType.BnbChain:
        return <img src={ChainBnbSVG} className={className} />;
      case EChainType.Polygon:
        return <img src={ChainPolygonSVG} className={className} />;
      case EChainType.Nervos:
        return <img src={ChainNervosSVG} className={className} />;
      case EChainType.Flow:
        return <img src={ChainFlowSVG} className={className} />;
      case EChainType.Conflux:
        return <img src={ChainConfluxSVG} className={className} />;
      case EChainType.Dfinity:
        return <img src={ChainDfinitySVG} className={className} />;
      case EChainType.Aptos:
        return <img src={ChainAptosSVG} className={className} />;
      // case EChainType.Chain33:
      //   return <img src={ChainChain33SVG} className={className} />;
      case EChainType.AntChain:
        return <img src={ChainAntSVG} className={className} />;
      case EChainType.FiscoBcos:
        return <img src={ChainFiscoSVG} className={className} />;
      case EChainType.AstarEvm:
        return <img src={ChainAstarSVG} className={className} />;
      case EChainType.AstarWasm:
        return <img src={ChainAstarSVG} className={className} />;
      case EChainType.Sui:
        return <img src={ChainSuiSVG} className={className} />;
      case EChainType.Xdc:
        return <img src={ChainXdcSVG} className={className} />;
      case EChainType.Iris:
        return <img src={ChainIrisSVG} className={className} />;
      default:
        return <></>;
    }
  }, [chain, className]);

  return icon || null;
}
