import { jwtDecode, JwtPayload } from 'jwt-decode';

import { EStorageKeys, storageUtil } from './storage';

export interface IUserInfo {
  userId: string;
  username: string;
  authorities: string[];
  blocksecAccessKey: string;
  email: string;
  githubToken?: string;
  loginType: LoginType;
}

export const initUserInfo: IUserInfo = {
  userId: '',
  username: '',
  authorities: [],
  blocksecAccessKey: '',
  email: '',
  githubToken: '',
  loginType: ''
};

export type LoginType =
  | 'GithubApp'
  | 'EmailCode'
  | 'EmailPassword'
  | 'UsernamePassword'
  | '';

interface JwtPayloadProps extends JwtPayload {
  appId: string;
  userId: string;
  email: string;
  strategy: LoginType;
  authorities: string[];
  githubToken: string;
}

export function getUserInfoFromLocal(): IUserInfo {
  const accessToken: string = storageUtil.get(EStorageKeys.ACCESS_TOKEN) || '';
  const { authorities: roles, ...rest } = storageUtil.get<IUserInfo>(
    EStorageKeys.USER_INFO
  );
  if (!accessToken || !rest.userId) {
    return initUserInfo;
  }
  const decodeData = jwtDecode<JwtPayloadProps>(accessToken);
  const { strategy } = decodeData;
  return {
    ...rest,
    loginType: strategy,
    authorities: roles || []
  };
}
