import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-use';
import semver from 'semver';

import StarIcon from '@/assets/images/project/star.inline.svg';
import { useTranslation } from '@/i18n';
import { DialogContext } from '@/libs/dialogClient/dialogProvider';
import { UserContext } from '@/libs/userClient/userProvider';
import { projectService } from '@/services/projectService';
import templateService from '@/services/templateService';
import { Locale } from '@/services/templateService/ITemplateService';
import pattern from '@/styles/pattern';
import customToast from '@/utils/customToast';

import { ButtonCommon, EButtonType } from '../common/ButtonCommon';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter
} from '../common/Dialog';

export function EditProjectInfoDialog() {
  const { isOpenEditDialog, setIsOpenEditDialog } = useContext(DialogContext);
  const { pathname } = useLocation();

  const {
    selectProjects,
    selectTemplates,
    setSelectProjects,
    setSelectTemplates,
    reloadProjectListNumber,
    setReloadProjectListNumber
  } = useContext(UserContext);
  const { i18n, t } = useTranslation();

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [version, setVersion] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const [versionMessage, setVersionMessage] = useState<string>('');

  const edit = useCallback(async () => {
    // if (!name || !description) return;
    if (!name) return;
    if (
      selectTemplates.length > 0 &&
      selectTemplates[0].isPublic &&
      semver.gt(selectTemplates[0]?.latestVersion, version)
    ) {
      setVersionMessage('Please enter correct version !');
      return;
    }
    try {
      setLoading(true);
      if (selectProjects.length > 0) {
        await projectService.updateProjectInfo(
          selectProjects[0].workspaceId,
          name,
          ''
          // description
        );
      }
      if (selectTemplates.length > 0 && !selectTemplates[0].isPublic) {
        await templateService.updatePrivateTemplateInfo({
          templateId: selectTemplates[0].id,
          name
          // description
        });
      }
      if (selectTemplates.length > 0 && selectTemplates[0].isPublic) {
        await templateService.createTemplate(
          selectTemplates[0].id,
          {
            isPublic: selectTemplates[0].isPublic,
            chain: selectTemplates[0].chain,
            name,
            description: '',
            // description,
            displayName: name,
            version,
            icon: 'https://marketplace-beta.media.chainide.com/templates/default_icon.svg',
            readmeFile:
              'https://marketplace-beta.media.chainide.com/templates/default_readme.md',
            defaultReadmePath: selectTemplates[0].defaultReadmePath,
            defaultOrder: selectTemplates[0].defaultOrder
          },
          i18n.language === 'en-US' ? Locale.EN : Locale.ZH
        );
      }
      setReloadProjectListNumber(reloadProjectListNumber + 1);

      customToast.success(t('edit_message_success'));
    } catch (e) {
      customToast.error((e as Error).message);
    } finally {
      setLoading(false);
      setIsOpenEditDialog(false);
    }
  }, [
    // description,
    i18n.language,
    name,
    reloadProjectListNumber,
    selectProjects,
    selectTemplates,
    setIsOpenEditDialog,
    setReloadProjectListNumber,
    t,
    version
  ]);

  useEffect(() => {
    setName(selectProjects[0]?.workspaceName ?? selectTemplates[0]?.name ?? '');
    setDescription(
      selectProjects[0]?.description ?? selectTemplates[0]?.description ?? ''
    );
    setVersion(selectTemplates[0]?.latestVersion);
  }, [selectProjects, selectTemplates]);

  return (
    <Dialog
      open={isOpenEditDialog}
      onOpenChange={() => {
        setSelectProjects([]);
        setSelectTemplates([]);
        setVersionMessage('');
        setIsOpenEditDialog(false);
      }}
    >
      <DialogContent>
        <div className=" flex  flex-col items-start justify-center">
          <div className=" mb-6 h-[27px] text-center text-textFirstSize font-bold leading-[27px] text-textPrimaryColor">
            {/* {i18n.t('edit')} */}
            {selectTemplates.length ? <span>Edit Public Template</span> : ''}
            {selectProjects.length ? <span>Edit Project</span> : ''}
          </div>
          <div className="mb-[32px] flex h-[69px] w-full flex-col">
            <label className="flex items-start ">
              <div className="text-textFourthSize text-[#D1D5DA]">
                {' '}
                {selectProjects.length ? (
                  <span>{i18n.t('project_name')}</span>
                ) : (
                  ''
                )}
                {selectTemplates.length ? <span>Template Name</span> : ''}
              </div>{' '}
              <StarIcon />
            </label>
            <input
              className="mt-[14px] rounded-common border-[1px] border-solid border-borderUlColor bg-bgInputColor py-3 px-4 outline-none placeholder:text-[#6F7780]"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </div>
          {selectTemplates.length ? (
            <div className="mt-4 mb-[32px] flex w-full flex-col gap-y-2">
              <label className="flex items-start">
                <div className="mt-1 text-textFourthSize text-[#D1D5DA]">
                  {i18n.t('description')}
                </div>{' '}
                <StarIcon />
              </label>
              <textarea
                className="min-h-[90px] rounded-common rounded-[6px] border-[1px] border-solid  border-borderUlColor bg-bgInputColor  py-3 px-4 outline-none"
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />
            </div>
          ) : (
            ''
          )}

          {selectTemplates.length > 0 && selectTemplates[0].isPublic && (
            <div className="flex w-full flex-col gap-y-2">
              <label className="flex items-start">
                <div className="mt-1 text-textFourthSize text-[#D1D5DA]">
                  {' '}
                  {i18n.t('dialog_edit_version')}
                </div>{' '}
                <StarIcon />
              </label>
              <input
                className="rounded-common border-[1px] border-solid border-solid border-borderUlColor bg-bgInputColor  py-3 px-4 outline-none placeholder:text-[#6F7780]"
                value={version}
                onChange={(event) => setVersion(event.target.value)}
              />
              {versionMessage && (
                <span className="-mt-[4px] text-textSixSize text-[red]">
                  {versionMessage}
                </span>
              )}
            </div>
          )}
        </div>
        <DialogFooter className={`  mt-8`}>
          <div className={` h-full w-full ${pattern.flexEnd}`}>
            <div className={` mr-[16px] h-[36px] `}>
              <DialogClose asChild={true}>
                <ButtonCommon
                  type={EButtonType.SIMPLE}
                  className="h-[36px] w-[82px] !rounded-[4px] text-textFourthSize text-[#ffffff] hover:bg-white-5"
                  onClick={() => {
                    setSelectProjects([]);
                    setSelectTemplates([]);
                  }}
                >
                  {i18n.t('cancel')}
                </ButtonCommon>
              </DialogClose>
            </div>
            <div className={`h-[36px]`}>
              <ButtonCommon
                loading={loading}
                type={EButtonType.PRIMARY}
                buttonType="submit"
                className="h-[36px] min-w-[82px] !rounded-[4px] text-textFourthSize text-[#ffffff] hover:bg-white-5"
                onClick={edit}
              >
                {i18n.t('confirm')}
              </ButtonCommon>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
