/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { BASE_URL } from 'gatsby-env-variables';
import { extend } from 'umi-request';

import { emitter, emitterEvent } from '@/utils/event';
import { EStorageKeys, storageUtil } from '@/utils/storage';
import { getUserInfoFromLocal } from '@/utils/tokenUtils';
import { TokenPairRestResponse, updateToken } from '@/utils/updateToken';

import { accountService } from './api/accountService';

export function logOut() {
  storageUtil.batchRemove([
    EStorageKeys.ACCESS_TOKEN,
    EStorageKeys.ACCESS_TOKEN_EXPIRE_TIME,
    EStorageKeys.REFRESH_TOKEN,
    EStorageKeys.REFRESH_TOKEN_EXPIRE_TIME,
    EStorageKeys.USER_INFO
  ]);
}
/** Request 网络请求工具 更详细的 api 文档: https://github.com/umijs/umi-request */
const request = extend({
  prefix: BASE_URL,
  timeout: 30 * 1000 // 30s
});

const tokenExcludeApiArr = [
  '/api/v1/user/github/login',
  '/api/v1/user/password/login',
  '/api/v1/user/refresh'
]; // 不需要token校验的路由白名单
let _cacheRequest: Array<{ url: string; options: any }> = [];
let isRefreshingToken = false; // 是否正在刷新token
let isShowLoginExpiredTip = false; // 是否正在显示过期登录提示

// @ts-ignore
request.interceptors.request.use(async (url, options) => {
  const accessToken = storageUtil.get(EStorageKeys.ACCESS_TOKEN);
  const accessTokenExpireTime = storageUtil.get<number>(
    EStorageKeys.ACCESS_TOKEN_EXPIRE_TIME
  );
  const refreshToken = storageUtil.get(EStorageKeys.REFRESH_TOKEN);
  const refreshTokenExpireTime = storageUtil.get<number>(
    EStorageKeys.REFRESH_TOKEN_EXPIRE_TIME
  );
  if (accessToken) {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      ...options.headers
    };

    if (tokenExcludeApiArr.includes(url.replace(BASE_URL, ''))) {
      return {
        url: url,
        options: { ...options, headers }
      };
    }

    const nowTime = Date.now();
    if (nowTime >= refreshTokenExpireTime) {
      console.log('refresh token过期');
      if (!isShowLoginExpiredTip) {
        isShowLoginExpiredTip = true;
        emitter.emit(emitterEvent.RefreshTokenExpired);
        setTimeout(() => {
          isShowLoginExpiredTip = false;
          logOut();
        }, 3000);
      }
    } else if (nowTime >= accessTokenExpireTime) {
      console.log('access token过期');
      // access token过期，执行refresh token
      if (isRefreshingToken) {
        _cacheRequest.push({ url, options });
      } else {
        isRefreshingToken = true;
        // 如果过期了还没请求新token,那就请求新token 记得带上旧token
        try {
          const data: TokenPairRestResponse = await accountService.refreshToken(
            refreshToken
          );
          updateToken(data);
          accountService
            .getUserInfo()
            .then((detail) => {
              storageUtil.set(EStorageKeys.USER_INFO, detail);
              accountService.updateUserDetailTrigger(getUserInfoFromLocal());
            })
            .catch(console.error);
          isRefreshingToken = false;
          _cacheRequest.forEach((value) => {
            void request(value.url.replace(BASE_URL, ''), value.options);
          });
          _cacheRequest = [];
        } catch (e) {
          console.error(e);
          emitter.emit(emitterEvent.RefreshTokenExpired);
          // refresh token失败，清空你的数据，直接返回登录，不允许操作了
          setTimeout(logOut, 3000);
        }
      }
    } else {
      return Promise.resolve({
        url,
        options: {
          ...options,
          headers
        }
      });
    }
  }

  return Promise.resolve({
    url,
    options
  });
});

// 响应拦截器
request.interceptors.response.use((response) => {
  if (
    response.status === 200 &&
    response.headers.get('Content-Type') === 'application/json'
  ) {
    return response.json().then((data) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return data;
    });
  }
  return response;
});

export async function putWithJson(url: string, data: Object, timeout?: number) {
  return request(url, {
    ...{
      method: 'put',
      requestType: 'json',
      data
    },
    ...(timeout ? { timeout } : {})
  });
}
export async function putWithForm(
  url: string,
  data: { [key: string]: string | Blob },
  timeout?: number
) {
  return request(url, {
    ...{
      method: 'put',
      requestType: 'form',
      data: genFormData(data)
    },
    ...(timeout ? { timeout } : {})
  });
}

export function postWithForm(
  url: string,
  data: { [key: string]: string | Blob },
  timeout?: number
) {
  return request(url, {
    ...{
      method: 'post',
      requestType: 'form',
      data: genFormData(data)
    },
    ...(timeout ? { timeout } : {})
  });
}

export function postWithJson(
  url: string,
  data: { [key: string]: string | Blob },
  timeout?: number
) {
  return request(url, {
    ...{
      method: 'post',
      requestType: 'json',
      data
    },
    ...(timeout ? { timeout } : {})
  });
}

export function deleteWithForm(
  url: string,
  data: { [key: string]: string | Blob },
  timeout?: number
) {
  return request(url, {
    ...{
      method: 'delete',
      requestType: 'form',
      data: genFormData(data)
    },
    ...(timeout ? { timeout } : {})
  });
}

function genFormData(data: { [key: string]: string | Blob }) {
  const _data = new FormData();
  Object.keys(data).forEach((k) => {
    _data.append(k, data[k]);
  });
  return _data;
}

export default request;
