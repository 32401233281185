import React, { createContext, useState } from 'react';

interface DialogContext {
  isOpenLoginDialog: boolean;
  setIsOpenLoginDialog: React.Dispatch<React.SetStateAction<boolean>>;
  isDeleteProjects: boolean;
  setIsDeleteProjects: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenEditDialog: boolean;
  setIsOpenEditDialog: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenSaveAsTemplate: boolean;
  setIsOpenSaveAsTemplate: React.Dispatch<React.SetStateAction<boolean>>;
}

interface PropsType {
  children?: JSX.Element;
}

export const DialogContext = createContext({} as DialogContext);

export const DialogProvider = (props: PropsType) => {
  const [isOpenLoginDialog, setIsOpenLoginDialog] = useState<boolean>(false);
  const [isDeleteProjects, setIsDeleteProjects] = useState<boolean>(false);
  const [isOpenEditDialog, setIsOpenEditDialog] = useState<boolean>(false);
  const [isOpenSaveAsTemplate, setIsOpenSaveAsTemplate] =
    useState<boolean>(false);

  return (
    <DialogContext.Provider
      value={{
        isOpenLoginDialog,
        setIsOpenLoginDialog,
        isDeleteProjects,
        setIsDeleteProjects,
        isOpenEditDialog,
        setIsOpenEditDialog,
        isOpenSaveAsTemplate,
        setIsOpenSaveAsTemplate
      }}
    >
      {props.children}
    </DialogContext.Provider>
  );
};
