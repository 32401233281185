/*
 * @Description:
 * @Author: didadida262
 * @Date: 2024-07-05 13:38:45
 * @LastEditors: didadida262
 * @LastEditTime: 2024-07-15 21:13:29
 */
import React, { ReactNode } from 'react';

import CreateProjectListBasicSVG from '@/assets/images/project/CreateProjectList_basic.inline.svg';
import CreateProjectListPrivateSVG from '@/assets/images/project/CreateProjectList_private.inline.svg';
import CreateProjectListPublicSVG from '@/assets/images/project/CreateProjectList_public.inline.svg';
import pattern from '@/styles/pattern';
import { copywriter } from '@/utils/copywriter';

const config = copywriter.projects;

interface ICreateProjectCardProps {
  title: string;
  descripttion: string;
  index: number;
  onClick: (val: number) => void;
}
export function CreateProjectCard({
  title,
  descripttion,
  index,
  onClick
}: ICreateProjectCardProps) {
  return (
    <div
      className={`flex h-[187px] w-[164px] flex-col items-center rounded-common border-[1px] border-solid border-[#2C2E36] px-[14px] py-[24px] hover:cursor-pointer hover:border-[#4A4F59] hover:bg-[#FFFFFF0D]`}
      onClick={() => {
        onClick(index);
      }}
    >
      <div
        className={`h-[40px] w-[48px] bg-[#2C2E3699] ${pattern.flexCenter} mb-[13px]`}
      >
        {index === 0 ? (
          <CreateProjectListBasicSVG />
        ) : index === 1 ? (
          <CreateProjectListPublicSVG />
        ) : (
          <CreateProjectListPrivateSVG />
        )}
      </div>
      <div className={`h-[19px] ${pattern.flexCenter} mb-[8px]`}>
        <span className={`text-textFourthSize leading-[18px]`}>{title}</span>
      </div>
      <div className={`h-[54px] ${pattern.flexCenter} text-center`}>
        <span className={`text-textSixSize leading-[16.8px] text-[#95A0AB]`}>
          {descripttion}
        </span>
      </div>
    </div>
  );
}
