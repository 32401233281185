/*
 * @Description:
 * @Author: didadida262
 * @Date: 2024-08-22 17:26:59
 * @LastEditors: didadida262
 * @LastEditTime: 2024-08-27 17:04:14
 */
import { navigate, type PageProps } from 'gatsby';
import React, { useCallback, useContext, useState } from 'react';
import { useMount, useSearchParam } from 'react-use';

import GithubIcon from '@/assets/images/common/github_icon.inline.svg';
import LoginLogoNew from '@/assets/images/common/login_logoNew.inline.svg';
import { ButtonCommon, EButtonType } from '@/components/common/ButtonCommon';
import { useTranslation } from '@/i18n';
import { DialogContext } from '@/libs/dialogClient/dialogProvider';
import { UserContext } from '@/libs/userClient/userProvider';
import {
  callbackPath,
  EAuthType,
  goToAuthCallbackCenter
} from '@/pages/authCallbackCenter';
import { accountService } from '@/services/api/accountService';
import pattern from '@/styles/pattern';
import customToast from '@/utils/customToast';
import { triggerGAEvent } from '@/utils/ga';
import { EStorageKeys, storageUtil } from '@/utils/storage';
import { IUserInfo } from '@/utils/tokenUtils';
import { updateToken } from '@/utils/updateToken';
import { UrlSearchUtils } from '@/utils/urlSearchUtils';
export const LOGIN_URL_AUTH_CODE_PARMA_KEY = 'code';
export const LOGIN_URL_AUTH_TYPE_KEY = 'auth_type';

const Login = ({ inititalLoading = false }: { inititalLoading?: boolean }) => {
  const { setUserInfo } = useContext(UserContext);
  const { isOpenLoginDialog, setIsOpenLoginDialog } = useContext(DialogContext);

  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const removeAuthParamsFromUrl = () => {
    const searchUtils = new UrlSearchUtils();
    searchUtils.deleteBatch([
      LOGIN_URL_AUTH_CODE_PARMA_KEY,
      LOGIN_URL_AUTH_TYPE_KEY
    ]);
    history.pushState('', '', searchUtils.url);
  };
  const handleClickLogin = useCallback(() => {
    setLoading(true);
    goToAuthCallbackCenter(EAuthType.OAUTH);
  }, []);
  const code = useSearchParam(LOGIN_URL_AUTH_CODE_PARMA_KEY);

  useMount(() => {
    // login modal是全局的组件，即使在auth callback center page，这个modal也会加载，这里要避免它消费code，应该先让auth callback center page做出来，然后回调到当前页面消费
    // auth callback 页面不应该消费code，code会传给初始页面消费
    if (location.pathname === callbackPath) return;
    if (code) {
      setLoading(true);
      accountService
        .githubOAuthCodeLogin(code)
        .then(async (data) => {
          updateToken(data);
          const userInfo = (await accountService.getUserInfo()) as IUserInfo;
          storageUtil.set(EStorageKeys.USER_INFO, userInfo);
          setUserInfo(userInfo);
          customToast.success(t('sign_in_message_success'));
          triggerGAEvent('login_success');
          void navigate('/projects');
        })
        .catch((e: Error) => {
          customToast.error(e.message);
        })
        .finally(() => {
          removeAuthParamsFromUrl();
          setIsOpenLoginDialog(false);
          setLoading(false);
        });
    }
  });
  return (
    <div
      className={` container relative h-full w-full max-w-[1440px] ${pattern.flexCenter}`}
    >
      {/* <div className={cn('markBorderG absolute left-0 top-0 h-full w-full')}>
          <LoginBg />
        </div> */}
      <div className="content  flex h-[282px] w-[400px] flex-col gap-y-[24px]">
        <div className="  h-[82px] w-full border-b-[1px] border-solid border-[#222837]">
          <LoginLogoNew />
        </div>
        <div className="title h-[30px] w-full">
          <span className="text-[24px] text-textPrimaryColor">
            Hi! Welcome to ChainIDE.
          </span>
        </div>
        <div className="des h-[18px] w-full">
          <span className="text-textFourthSize text-[#949699]">
            Get a free account instantly with your GitHub login.
          </span>
        </div>
        <div className="btn">
          <ButtonCommon
            type={EButtonType.PRIMARY}
            buttonType="submit"
            className="w-full gap-x-2 !rounded-[4px] !bg-[#146EF5] py-3 text-textFourthSize text-textPrimaryColor hover:bg-white-5"
            onClick={handleClickLogin}
            disable={loading || inititalLoading}
            loading={loading || inititalLoading}
          >
            <GithubIcon />
            {/* {t('sign_in_github')} */}
            <span className="text-textFourthSize text-textPrimaryColor">
              Continue with GitHub
            </span>
          </ButtonCommon>
        </div>
      </div>
    </div>
  );
};
export default Login;
