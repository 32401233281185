export const copywriter = {
  projects: {
    CreateProjectList: [
      {
        title: 'Basic Configuration',
        description:
          'Begin your project with a simplified setup to get started quickly.'
      },
      {
        title: 'Public Template',
        description:
          'Explore and select from publicly available templates to kickstart your project.'
      },
      {
        title: 'Private template',
        description:
          'Begin your project with a simplified setup to get started quickly.'
      }
    ]
  }
};
