/*
 * @Description:
 * @Author: didadida262
 * @Date: 2024-05-10 11:26:02
 * @LastEditors: didadida262
 * @LastEditTime: 2024-07-15 10:53:46
 */
import cn from 'classnames';
import React, { ReactNode } from 'react';

interface IProgressBarProps {
  className?: string;
  label: string;
  process: number;
  totalProcess: number;
  unit?: string;
  totalProcessColor: string;
  processColor: string;
  extraComponent?: ReactNode;
}
export function ProgressBar(props: IProgressBarProps) {
  const {
    className,
    label,
    process,
    totalProcess,
    unit,
    totalProcessColor,
    processColor,
    extraComponent
  } = props;

  return (
    <div className={cn(className, 'text-textSixSize ')}>
      <div className="flex justify-between">
        <div
          className="flex items-center gap-1 text-[#D1D5DA]"
          style={{ width: '80px' }}
        >
          <label>{label}</label>
          {extraComponent}
        </div>

        <div
          className="flex items-center"
          style={{ width: 'calc(100% - 50px)' }}
        >
          <div
            className="h-1 w-[100%]"
            style={{
              background: totalProcessColor,
              width: '100%'
            }}
          >
            <div
              className="h-full "
              style={{
                width: `${(process / totalProcess) * 100}%`,
                maxWidth: '100%',
                background: processColor
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
