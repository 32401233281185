import { Search } from '@/components/common/Search';

export enum PluginType {
  view = 'view',
  server = 'server'
}

export enum SourceType {
  GIST = 'GIST',
  GIT = 'GIT',
  TEMPLATE = 'TEMPLATE',
  ZIP = 'ZIP'
}

export interface PluginConfigurations {
  pluginId: string;
  version: string;
  type: PluginType;
  active: boolean;
  internalPlugin?: boolean;
  libraryInfo?: {
    url: string;
  };
  description: {
    title: string;
    icon?: string;
    inactiveIcon?: string;
    description?: string;
  };
}
export interface CurrentProjectPluginsConfig {
  currentProjectId: string | null;
  currentProjectType: ProjectType | null;
  pluginsConfigs: { [pluginId: string]: PluginConfigurations };
}

export enum ProjectType {
  /**
   * 根据公有模板创建的项目
   */
  PUBLIC = 'PUBLIC',
  /**
   * 根据私有模板创建的项目
   */
  PRIVATE = 'PRIVATE',
  /**
   * 编辑公有模板时产生的临时工程
   */
  PUBLIC_TEMPLATE = 'PUBLIC_TEMPLATE',
  /**
   * 编辑私有模板时产生的临时工程
   */
  PRIVATE_TEMPLATE = 'PRIVATE_TEMPLATE'
}

export enum EProjectAnonymousStatus {
  'ANONYMOUS' = 'true',
  'NOTANONYMOUS' = 'false'
}

export interface IProject {
  workspaceId: string;
  workspaceName: string;
  deviceId: string;
  userId: string;
  type: ProjectType;
  fromChain: string;
  createTime?: string | number;
  lastActiveTime?: string | number;
  thumbnail?: string;
  defaultReadmePath?: string;
  diskUsage?: string;
  diskQuota?: string;
  memoryQuota?: string;
  cpuQuota?: string;
  hardLimit?: string;
  softLimit?: string;
  running: boolean;
  template?: string;
  terminal: boolean;
  pluginInfo?: string | CurrentProjectPluginsConfig;
  description?: string;
  anonymous?: EProjectAnonymousStatus;
}
export interface IProjectPrivate {
  chain: string;
  workspaceId: string;
  workspaceName: string;
  name: string;
  deviceId: string;
  userId: string;
  type: ProjectType;
  fromChain: string;
  createTime?: string | number;
  lastActiveTime?: string | number;
  thumbnail?: string;
  defaultReadmePath?: string;
  diskUsage?: string;
  diskQuota?: string;
  memoryQuota?: string;
  cpuQuota?: string;
  hardLimit?: string;
  softLimit?: string;
  running: boolean;
  template?: string;
  terminal: boolean;
  pluginInfo?: string | CurrentProjectPluginsConfig;
  description?: string;
  anonymous?: EProjectAnonymousStatus;
}

export interface IProjectQueryParams {
  deviceId: string;
  chain?: string;
  limit?: number;
  page?: number;
  running?: boolean;
  searchText?: string;
  status: string;
}

export interface IProjectQueryResponse {
  hasNextPage: boolean;
  page: number;
  workspaceInfoList: IProject[];
}

export interface IProjectService {
  // 项目增删改查
  createProjectByTemplate(
    deviceId: string,
    fromChain: string,
    templateId: string,
    cpuQuota?: string,
    diskQuota?: string,
    memQuota?: string
  ): Promise<string>;

  getProjects(deviceId: string): Promise<IProject[]>;

  getProject(id: string): Promise<IProject | null>;

  updateProjectInfo(
    projectId: string,
    workspaceName: string,
    description: string
  ): Promise<void>;

  setDefaultReadmePath(id: string, path?: string): Promise<void>;

  removeProject(id: string): Promise<void>;

  removeProjects(workspaceIds: string[]): Promise<void>;

  anonymousTransfer2User(id: string): Promise<IProject | null>;

  checkProjectExisted(source: string, partnerId: string): Promise<boolean>;

  createProjectByGit(
    deviceId: string,
    chain: string,
    url: string,
    branch: string,
    githubAuthToken?: string,
    cpuQuota?: string,
    diskQuota?: string,
    memQuota?: string
  ): Promise<string>;

  createProjectByGist(
    deviceId: string,
    chain: string,
    gistUrl: string,
    githubAuthToken: string,
    cpuQuota?: string,
    diskQuota?: string,
    memQuota?: string
  ): Promise<string>;

  createProjectByZip(
    zipFileName: string,
    deviceId: string,
    chain: string,
    cpuQuota?: string,
    diskQuota?: string,
    memQuota?: string
  ): Promise<string>;

  uploadFile(file: File): Promise<string>;
  packageWorkspace(url: string): Promise<string>;

  queryWorkspacePage(
    params: IProjectQueryParams
  ): Promise<IProjectQueryResponse>;

  getWorkspaceInfo(workspaceId: string): Promise<IProject>;
}
