/* eslint-disable @typescript-eslint/no-unsafe-call */
declare global {
  interface Window {
    gtag: any;
  }
}

export function triggerGAEvent(eventName: string, params = {}) {
  if (window.gtag) {
    window.gtag('event', eventName, {
      ...params,
      event_category: eventName,
      event_label: eventName
    });
  }
}
