import { useLocation } from '@reach/router';
import cn from 'classnames';
import { Link, navigate, withPrefix } from 'gatsby';
import React, { useContext, useState } from 'react';

import PlugSVG from '@/assets/images/common/plus2.inline.svg';
import bilingSVGActive from '@/assets/images/sidebar/bilingSVGActive.inline.svg';
import ExtensionActiveSvg from '@/assets/images/sidebar/extension_active.inline.svg';
import learnSvgActive from '@/assets/images/sidebar/learnSVGActive.inline.svg';
import ProjectActiveSVG from '@/assets/images/sidebar/project_active.inline.svg';
import TemplateActiveSVG from '@/assets/images/sidebar/template_active.inline.svg';
import { ButtonCommon, EButtonType } from '@/components/common/ButtonCommon';
import { useTranslation } from '@/i18n';
import { ThemeContext } from '@/libs/themeClient/themeProvider';
import pattern from '@/styles/pattern';
import { EPagePath } from '@/utils/const';

interface MenuListType {
  title: string;
  activeIcon?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  deActiveIcon: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  link?: string;
  sub?: MenuListType[];
}

const menuList: MenuListType[] = [
  {
    title: 'projects',
    activeIcon: ProjectActiveSVG,
    deActiveIcon: ProjectActiveSVG,
    link: EPagePath.ProjectListPage
  },
  {
    title: 'templates',
    deActiveIcon: TemplateActiveSVG,
    activeIcon: TemplateActiveSVG,
    link: EPagePath.TemplateListPage
  },
  {
    title: 'extensions',
    deActiveIcon: ExtensionActiveSvg,
    activeIcon: ExtensionActiveSvg,
    link: EPagePath.ExtensionsListPage
  }
  // {
  //   title: 'Learn',
  //   deActiveIcon: learnSvgActive,
  //   activeIcon: learnSvgActive,
  //   link: EPagePath.LearnPage
  // },
  // {
  //   title: 'Biling',
  //   deActiveIcon: bilingSVGActive,
  //   activeIcon: bilingSVGActive,
  //   link: EPagePath.BilingPage
  // }
];

export function NavList() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [isExpandTemplateMenu, setExpandTemplateMenu] = useState(true);
  const { currentTheme } = useContext(ThemeContext);

  return (
    <nav className="flex flex-col gap-y-[0px] py-5">
      <div className=" mb-[17px] w-full px-[24px]">
        <ButtonCommon
          className="h-[32px] w-full !rounded-[4px] text-textFirstSize font-[400] text-[#ffffff] hover:bg-white-5"
          type={EButtonType.SIMPLE}
          onClick={(e) => {
            // 创建
            void navigate(EPagePath.ProjectCreatePage, {
              state: { prevPath: window.location.pathname }
            });
            e.stopPropagation();
          }}
        >
          <span
            className={`text-textFourthSize ${pattern.flexbet} gap-x-[8px]`}
          >
            <PlugSVG /> {t('create')}
          </span>
        </ButtonCommon>
      </div>
      {menuList.map((menu) => {
        const prefixLink = withPrefix(menu.link || '');
        const isMenuActive = pathname.includes(prefixLink);
        // console.log(pathname, menu.link, isMenuActive);
        if (menu.sub?.length) {
          // 代表有二级菜单
          return (
            <div key={menu.title}>
              <div
                className="mb-2 font-Poppins text-textFourthSize text-textSecondaryColor"
                style={{ paddingLeft: '16px' }}
              >
                {t('marketplace')}
              </div>
              <div
                className="flex cursor-pointer items-center justify-between pl-4 pr-1"
                onClick={() => {
                  setExpandTemplateMenu(!isExpandTemplateMenu);
                }}
              >
                <div className="flex items-center gap-x-1">
                  <menu.deActiveIcon className="h-6 w-6" />
                  <span className="text-[#a0a1b0]">{menu.title}</span>
                </div>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.9999 7.9952C16.0004 8.12324 15.9721 8.24975 15.9172 8.36544C15.8624 8.48113 15.7823 8.58305 15.6828 8.66371L10.5405 12.8033C10.3871 12.9294 10.1947 12.9983 9.99622 12.9983C9.7977 12.9983 9.60534 12.9294 9.45199 12.8033L4.30962 8.51801C4.13459 8.37253 4.02452 8.16349 4.00363 7.93686C3.98273 7.71023 4.05272 7.48458 4.1982 7.30955C4.34367 7.13453 4.55272 7.02446 4.77935 7.00356C5.00598 6.98267 5.23163 7.05266 5.40665 7.19813L10.0005 11.0292L14.5944 7.32669C14.7202 7.22189 14.8734 7.15532 15.0358 7.13485C15.1983 7.11438 15.3632 7.14088 15.5111 7.21121C15.6589 7.28154 15.7835 7.39275 15.8702 7.53169C15.9568 7.67062 16.0019 7.83147 15.9999 7.9952Z"
                    fill="#A0A1B0"
                    className={cn(
                      'origin-center duration-300',
                      isExpandTemplateMenu ? 'rotate-180' : 'rotate-0'
                    )}
                  />
                </svg>
              </div>
              <ul
                className={cn(
                  'mt-[8px] flex flex-col gap-y-1 overflow-hidden duration-300',
                  isExpandTemplateMenu ? 'h-[68px]' : 'h-0'
                )}
              >
                {menu.sub.map((submenu) => {
                  const subPrefixLink = withPrefix(submenu.link || '');
                  const isSubMenuActive = pathname === subPrefixLink;
                  return (
                    <Link
                      to={submenu.link || ''}
                      key={submenu.title}
                      className={cn(
                        ' flex h-[22px] items-center gap-x-1 pl-10 pr-1',
                        isSubMenuActive
                          ? 'bg-themeColor text-white'
                          : 'text-[#a0a1b0]'
                      )}
                    >
                      {isSubMenuActive && submenu.activeIcon ? (
                        <submenu.activeIcon />
                      ) : (
                        <submenu.deActiveIcon />
                      )}
                      <span>{t(submenu.title)}</span>
                    </Link>
                  );
                })}
              </ul>
            </div>
          );
        } else {
          return (
            <div
              key={menu.title}
              className={cn(
                ` h-[40px] px-[24px] ${pattern.flexCenter}`,
                [''].includes(menu.title)
                  ? 'border-b-solid mb-[4px] border-b-[1px] border-b-[#22252A] pb-[8px]'
                  : ''
              )}
            >
              <Link
                to={menu.link || ''}
                className={cn(
                  ' gap-x-1text-textTertiaryColor flex h-[32px] w-full items-center rounded-[4px] pl-[8px] text-textFourthSize text-[#E1E4E8]',
                  isMenuActive && 'bg-bgLinkColor2'
                )}
              >
                {isMenuActive && menu.activeIcon ? (
                  <menu.activeIcon
                    className={
                      `linkSvgDarkNav`
                      // currentTheme === 'Light Mode'
                      //   ? menu.title === 'extensions'
                      //     ? 'linkSvgLightExten'
                      //     : 'linkSvgLight'
                      //   : 'linkSvgDark'
                    }
                  />
                ) : (
                  <menu.deActiveIcon />
                )}
                <span className="ml-[8px] text-textFourthSize">
                  {t(menu.title)}
                </span>
                {/*  */}
              </Link>
              {/* <div>下划线</div> */}
            </div>
          );
        }
      })}
    </nav>
  );
}
