/* eslint-disable node/no-unsupported-features/node-builtins */
export class UrlSearchUtils {
  private _url: URL;

  /**
   * 构造器
   * @param url default window.location.href
   */
  constructor(url = window.location.href) {
    this._url = new URL(url);
  }

  delete(key: string) {
    this._url.searchParams.delete(key);
  }

  deleteBatch(keys: string[]) {
    for (const key of keys) {
      this.delete(key);
    }
  }

  append(key: string, value: string) {
    this._url.searchParams.append(key, value);
  }

  appendBatch(data: Array<{ key: string; value: string }>) {
    for (const { key, value } of data) {
      this.append(key, value);
    }
  }

  set(key: string, value: string) {
    this._url.searchParams.set(key, value);
  }

  has(key: string) {
    this._url.searchParams.has(key);
  }

  get(key: string) {
    return this._url.searchParams.get(key);
  }

  get url() {
    return this._url.toString();
  }
}
