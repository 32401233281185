/* eslint-disable @typescript-eslint/no-unsafe-return */
import request from '../request';
import {
  IPortForwardResponse,
  IPortForwardService,
  IResourceQuota,
  IResourceUsage
} from './IPortForwardService';

export class PortForwardServiceImpl implements IPortForwardService {
  async getOpensumiPortForward(
    workspaceId: string
  ): Promise<IPortForwardResponse> {
    const { data } = await request(
      `/api/workspaces/${workspaceId}/port-forwards/8000`,
      {
        method: 'get',
        requestType: 'form',
        params: {
          workspaceId
        }
      }
    );
    return data as IPortForwardResponse;
  }

  async getResourceUsage(url: string): Promise<IResourceUsage> {
    const data = await request(`/resource/usage`, {
      method: 'get',
      requestType: 'form',
      prefix: `${url}`
    });
    return data;
  }

  async getUserResourceQuota(): Promise<IResourceQuota> {
    const data = await request('/api/v1/user/resourceQuota', {
      method: 'get',
      requestType: 'form'
    });
    return data;
  }

  async getUserResourceAlloc(): Promise<IResourceQuota> {
    const data = await request('/api/v1/user/resourceAlloc', {
      method: 'get',
      requestType: 'form'
    });
    return data;
  }
}
