import { EChainType } from '@/utils/const';

export enum Locale {
  ZH = 'zh',
  EN = 'en'
}
export type FileType = '-' | 'd';

export interface ITemplate {
  name: string;
  path: string;
  id: string;
  icon: string;
  isPublic: boolean;
  description: string;
  latestVersion: string;
  displayName: string;
  publishedDate: number;
  readmeFile: string;
  defaultReadmePath: string;
  chain: EChainType;
  trending: boolean;
  defaultOrder: number;
  isDefault: boolean;
  reviewCount: number;
  viewCount: number;
  averageRating: number;
  downloadCount: number;
  lang: 'en' | 'zh';
}

export interface ICreateTemplateParam {
  chain: string;
  name: string;
  description?: string;
  isPublic?: boolean;
  version?: string;
  readmeFile?: string;
  defaultReadmePath?: string;
  defaultOrder?: number;
  icon?: string;
  isDefault?: boolean;
  displayName?: string;
}

export interface ITemplateContent {
  path: string;
  name: string;
  content: string;
  type: FileType;
}

export interface ITemplateInfo {
  templateId: string;
  name?: string;
  displayName?: string;
  description?: string;
}

export interface ITemplateAndContent {
  template: ITemplate;
  content: ITemplateContent[];
}

export interface ITemplateService {
  createTemplate(
    workspaceId: string,
    params: ICreateTemplateParam,
    language: Locale
  ): Promise<string>;
  getTemplates(
    language: Locale,
    isPublic?: boolean,
    chain?: string,
    sort?: string
  ): Promise<ITemplate[]>;
  getTemplate(templateId: string): Promise<ITemplate>;
  removeTemplates(templateIds: string[]): Promise<void>;
  removeTemplate(templateId: string): Promise<void>;
  updateTemplateInfo(
    templateId: string,
    name: string,
    description: string
  ): Promise<void>;
  updatePrivateTemplateInfo(params: ITemplateInfo): Promise<void>;
  updateTemplateFiles(templateId: string): Promise<void>;
  updateTemplatesOrder(
    templatesIdList: string,
    orderList: string
  ): Promise<void>;
}
