import React, { useCallback, useContext, useEffect, useState } from 'react';

import StarIcon from '@/assets/images/project/star.inline.svg';
import { useTranslation } from '@/i18n';
import { DialogContext } from '@/libs/dialogClient/dialogProvider';
import { UserContext } from '@/libs/userClient/userProvider';
import portForwardService from '@/services/portForwards';
import { projectService } from '@/services/projectService';
import templateService from '@/services/templateService';
import { Locale } from '@/services/templateService/ITemplateService';
import pattern from '@/styles/pattern';
import customToast from '@/utils/customToast';

import { ButtonCommon, EButtonType } from '../common/ButtonCommon';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter
} from '../common/Dialog';

export function SaveAsTemplate() {
  const { isOpenSaveAsTemplate, setIsOpenSaveAsTemplate } =
    useContext(DialogContext);
  const {
    selectProjects,
    reloadProjectListNumber,
    setReloadProjectListNumber
  } = useContext(UserContext);
  const { i18n, t } = useTranslation();

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const doSaveAsTemplate = useCallback(async () => {
    // if (!name || !description || !selectOption) return;
    if (!name) return;
    try {
      setLoading(true);
      if (selectProjects[0].running) {
        const portForwardData = await portForwardService.getOpensumiPortForward(
          selectProjects[0].workspaceId
        );
        await projectService.packageWorkspace(
          `https://${portForwardData.host}`
        );
      }

      await templateService.createTemplate(
        selectProjects[0].workspaceId,
        {
          // isPublic: selectOption.value === 'publicTemplate',
          isPublic: false,
          isDefault: false,
          chain: selectProjects[0].fromChain,
          name,
          description: 'default_description',
          displayName: name,
          version: '0.0.1',
          icon: 'https://marketplace-beta.media.chainide.com/templates/default_icon.svg',
          readmeFile:
            'https://marketplace-beta.media.chainide.com/templates/default_readme.md',
          defaultReadmePath: selectProjects[0].defaultReadmePath || '/',
          defaultOrder: 0
        },
        i18n.language === 'en-US' ? Locale.EN : Locale.ZH
      );
      setReloadProjectListNumber(reloadProjectListNumber + 1);
      customToast.success(t('save_as_template_success'));
    } catch (e) {
      customToast.error((e as Error).message);
    } finally {
      setLoading(false);
      setIsOpenSaveAsTemplate(false);
    }
  }, [
    // description,
    i18n.language,
    name,
    reloadProjectListNumber,
    // selectOption,
    selectProjects,
    setIsOpenSaveAsTemplate,
    setReloadProjectListNumber,
    t
  ]);

  useEffect(() => {
    setName(selectProjects[0]?.workspaceName);
    setDescription(selectProjects[0]?.description ?? '');
  }, [selectProjects]);

  return (
    <Dialog open={isOpenSaveAsTemplate} onOpenChange={setIsOpenSaveAsTemplate}>
      <DialogContent>
        <div className=" flex flex-col items-start justify-start">
          <div className="mb-6">
            <span
              className={`text-center text-textFirstSize font-bold leading-[27px] text-textPrimaryColor`}
            >
              {i18n.t('save_as_template')}
            </span>
          </div>

          <div className="flex w-full flex-col gap-y-2">
            <label className="flex items-start text-textFourthSize text-[#D1D5DA]">
              <div className="mt-1">{i18n.t('template_name')}</div> <StarIcon />
            </label>
            <input
              className="rounded-common border-[1px] border-solid border-borderUlColor bg-bgInputColor py-3 px-4 outline-none placeholder:text-[#6F7780]"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </div>

          {/* <div className="mt-4 flex w-full flex-col gap-y-2">
            <label className="flex items-start">
              <div className="mt-1">{i18n.t('template_desc')}</div> <StarIcon />
            </label>
            <textarea
              className="min-h-[90px] rounded-[6px] border-0 bg-bgInputColor py-3 px-4 outline-none"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </div> */}
          {/* 
          <div className="mt-4 flex w-full flex-col gap-y-2">
            <label className="flex items-start">
              <div className="mt-1">{i18n.t('template_type')}</div> <StarIcon />
            </label>
            <Select
              className="w-full"
              selectOption={selectOption}
              onChange={setSelectOption}
              options={originTypeDropDownOptions}
            />
          </div> */}
        </div>
        <DialogFooter className="mt-8">
          <div className={`h-full w-full ${pattern.flexEnd}`}>
            <div className={` mr-[16px] h-[36px]`}>
              <DialogClose asChild={true}>
                <ButtonCommon
                  type={EButtonType.SIMPLE}
                  className="h-[36px] w-[82px] !rounded-[4px] text-textFourthSize text-[#ffffff] hover:bg-white-5"
                >
                  {i18n.t('cancel')}
                </ButtonCommon>
              </DialogClose>
            </div>
            <div className={` h-[36px]`}>
              <ButtonCommon
                loading={loading}
                type={EButtonType.PRIMARY}
                buttonType="submit"
                className="h-[36px] min-w-[82px] !rounded-[4px] text-textFourthSize text-[#ffffff] hover:bg-white-5"
                onClick={doSaveAsTemplate}
              >
                {i18n.t('confirm')}
              </ButtonCommon>
            </div>
          </div>
          {/* <DialogClose asChild={true}>
            <Button
              type={EButtonType.GHOST}
              className="h-10 w-full font-medium text-[#ffffff] sm:w-[192px]"
            >
              {i18n.t('cancel')}
            </Button>
          </DialogClose>
          <Button
            loading={loading}
            type={EButtonType.PRIMARY}
            buttonType="submit"
            className="h-10 w-full font-medium text-[#ffffff] sm:w-[192px]"
            onClick={doSaveAsTemplate}
          >
            {i18n.t('confirm')}
          </Button> */}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
