exports.components = {
  "component---src-pages-auth-callback-center-tsx": () => import("./../../../src/pages/authCallbackCenter.tsx" /* webpackChunkName: "component---src-pages-auth-callback-center-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-login-login-tsx": () => import("./../../../src/pages/login/login.tsx" /* webpackChunkName: "component---src-pages-login-login-tsx" */),
  "component---src-pages-plugins-index-tsx": () => import("./../../../src/pages/plugins/index.tsx" /* webpackChunkName: "component---src-pages-plugins-index-tsx" */),
  "component---src-pages-plugins-plugin-id-index-tsx": () => import("./../../../src/pages/plugins/[pluginId]/index.tsx" /* webpackChunkName: "component---src-pages-plugins-plugin-id-index-tsx" */),
  "component---src-pages-projects-create-tsx": () => import("./../../../src/pages/projects/create.tsx" /* webpackChunkName: "component---src-pages-projects-create-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-projects-no-projects-tsx": () => import("./../../../src/pages/projects/NoProjects.tsx" /* webpackChunkName: "component---src-pages-projects-no-projects-tsx" */),
  "component---src-pages-templates-index-tsx": () => import("./../../../src/pages/templates/index.tsx" /* webpackChunkName: "component---src-pages-templates-index-tsx" */),
  "component---src-pages-templates-template-id-index-tsx": () => import("./../../../src/pages/templates/[templateId]/index.tsx" /* webpackChunkName: "component---src-pages-templates-template-id-index-tsx" */)
}

