/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import amplitude from 'amplitude-js';
// 需要搜集浏览器的一些参数来生成唯一id，因此要封装成hooks形式，追踪变化调用effect
export function useGetDeviceId() {
  return { deviceId: amplitude.getInstance().options.deviceId || '' };
}
// 非hook中使用，注意返回值很可能为空字符串
export function getDeviceId() {
  return amplitude.getInstance().options.deviceId || '';
}
