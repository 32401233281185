/*
 * @Description:
 * @Author: didadida262
 * @Date: 2024-05-10 11:26:02
 * @LastEditors: didadida262
 * @LastEditTime: 2024-08-27 10:30:20
 */
import Intercom from '@intercom/messenger-js-sdk';
import cn from 'classnames';
import React, { ReactNode, useContext, useEffect } from 'react';
import { useLocation, useMount } from 'react-use';

import AuthRoute from '@/components/common/AuthRoute';
import { UserContext } from '@/libs/userClient/userProvider';
import { callbackPath } from '@/pages/authCallbackCenter';
import { accountService } from '@/services/api/accountService';
import { useGetDeviceId } from '@/utils/hooks/useGetDeviceId';
import { useGetUserHash } from '@/utils/hooks/useGetUserHash';
import { useSize } from '@/utils/hooks/useSize';

import { EditProjectInfoDialog } from '../dialogs/EditProjectInfoDialog';
import { SaveAsTemplate } from '../dialogs/SaveAsTemplate';
import { Header } from './Header';
import { SideBar } from './SideBar';

interface LayoutProps {
  [key: string]: unknown;
  className?: string;
  children: ReactNode;
}

export function Layout(props: LayoutProps) {
  const { className, children } = props;
  const { is3XL, isWidthLoading } = useSize();
  const { pathname } = useLocation();
  const { setUserInfo } = useContext(UserContext);
  const { userInfo } = useContext(UserContext);
  const { userHash } = useGetUserHash();
  const { deviceId } = useGetDeviceId();

  useEffect(() => {
    const event = accountService.onUserDetailChange(({ data }) => {
      setUserInfo(data);
    });
    return () => {
      event.dispose();
    };
  }, [setUserInfo]);

  useEffect(() => {
    if (userHash) {
      Intercom({
        api_base: 'https://api-iam.intercom.io',
        app_id: 'abs7pjk8',
        user_id: userInfo.userId || deviceId, // User ID
        user_hash: userHash, // HMAC using SHA-256 hash of the user's email address
        name: userInfo.userId || deviceId, // Full name
        email: userInfo.email, // Email address
        vertical_padding: 40,
        horizontal_padding: 40
      });
    }
  }, [deviceId, userHash, userInfo]);

  // 防止页面闪烁
  if (isWidthLoading) return <></>;
  // auth callback page，不需要layout
  if (pathname === callbackPath) return children;
  return (
    <AuthRoute>
      <div
        className={cn(className, 'flex h-screen flex-col overflow-y-hidden')}
      >
        {/* {userInfo.userId && <Header />} */}
        <Header />
        <div className=" flex h-[calc(100%_-_64px)] flex-1 flex-row">
          {/* {userInfo.userId && <SideBar />} */}
          <SideBar />
          <main className="h-full w-full overflow-y-auto bg-bgPrimaryContentColor">
            {children}
          </main>
        </div>

        {/* <LoginDialog /> */}
        <EditProjectInfoDialog />
        <SaveAsTemplate />
      </div>
    </AuthRoute>
  );
}
