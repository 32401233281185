import { EStorageKeys, storageUtil } from './storage';

export interface TokenPairRestResponse {
  /** access token */
  accessToken?: string;
  /** expires seconds */
  expiresIn?: number;
  /** refresh token */
  refreshToken?: string;
  /** scope */
  scope?: string;
  /** token type */
  tokenType?: string;
}

export function updateToken(v: TokenPairRestResponse) {
  const now = Date.now();
  if (v.accessToken) {
    storageUtil.set(EStorageKeys.ACCESS_TOKEN, v.accessToken);
  }
  if (v.expiresIn) {
    // 设置access token 过期时间
    const tokenExpireTime = now + Number(v.expiresIn) * 1000;
    // const tokenExpireTime = now + 20 * 1000;
    storageUtil.set(EStorageKeys.ACCESS_TOKEN_EXPIRE_TIME, tokenExpireTime);
  }
  if (v.refreshToken) {
    storageUtil.set(EStorageKeys.REFRESH_TOKEN, v.refreshToken);
    // refresh token过期时间和后端约定为24小时
    const refreshTokenExpireTime = now + 24 * 60 * 60 * 1000;
    // const refreshTokenExpireTime = now + 1 * 1 * 30 * 1000;
    storageUtil.set(
      EStorageKeys.REFRESH_TOKEN_EXPIRE_TIME,
      refreshTokenExpireTime
    );
  }
}
