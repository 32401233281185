export default {
  // common
  common_or: 'or',

  project_create_new_projects: 'Create A New Project',
  sign_in: 'Sign in',
  log_out: 'Log out',

  marketplace: 'Marketplace',
  studio: 'Studio',
  templates: 'Templates',
  projects: 'Projects',
  extensions: 'Extensions',
  search_here: 'Search here',
  running: 'Running',

  manage: 'Manage',
  select_all: 'Select All',
  delete: 'Delete',
  delete_project: 'Are you sure to delete the selected project ?',
  delete_the_projects: 'Are you sure to delete the selected projects ?',

  delete_templates: 'templates',
  delete_one_project: 'Are you sure to delete this Project',
  delete_one_template: 'Are you sure to delete this template',
  delete_cancel: 'Cancel',
  delete_confirm: 'Delete',

  support: 'Support',
  chatbot_title: 'Chatbot',
  support_chatbot_desc:
    'We are planning to integrate chatbot functionality in the future. Stay tuned for updates!',
  doc_title: 'Documents',
  support_doc_desc:
    'We offer Chinese and English tutorials for you to learn how to use ChainIDE.',
  forum_title: 'Forum',
  support_forum_desc:
    'ChainIDE Forum is a place to discuss information about blockchain. Welcome to join us and participate in discussions!',
  feedback_title: 'Feedback',
  support_feedback_desc: `We believe the core of ChainIDE is the user, and we are always happy to hear the community's feedback. Feel free to reach us if you get any feedback.`,
  update_title: 'Update',
  opensource: 'Open Sources',
  support_update_desc: `V 2.1.7 (01-16-2023) This version's main changes are adding the Solidity smart contract verifier plug-in "chainide - verifier" and the jump function to a Solidity file to jump and view the imported source code.`,

  // dailog login
  sign_in_to_chainide: 'Sign in to ChainIDE',
  get_a_free_account: 'Get a free account, no creait card repuired.',
  sign_in_github: 'Sign in with GitHub',
  continue_as_guest: 'Continue as Guset',
  // dailog editproject
  edit: 'Edit',

  project_name: 'Project Name',
  description: 'Description',
  cancel: 'Cancel',
  confirm: 'Confirm',
  dialog_edit_version: 'Version',
  // dialog save as template
  save_as_template: 'Save as Private Template',
  template_name: 'Template Name',
  template_desc: 'Template Description',
  template_type: 'Template Type',

  // create a project page
  project_create_project: 'Choose How to Start Your Project',
  project_create_github_project: 'Import from GitHub',
  project_create_gist_project: 'Import from Gist',
  project_create_files_project: 'Import from Local Files',
  project_create_private_project: 'Select Private Template',

  project: 'Project',
  welcome_create_project: `Welcome to the Create Projects feature, an integral tool designed to
  optimize your experience. Please choose to use a template or import a
  project.`,
  choose_a_approach: 'Choose an approach',
  blockchain: 'Blockchain',
  start_button_text: 'Start',
  choose_button_text: 'Choose',
  start_with_basic_configuration: 'Start With Basic Configuration',
  start_with_basic_configuration_des:
    'Begin your project with a simplified setup to get started quickly. ',
  choose_public_template: 'Choose Public Template',
  choose_public_template_des:
    'Explore and select from publicly available templates to kickstart your project. ',
  choose_private_template: 'Choose Private Template',
  choose_private_template_des:
    'Select a template from your privately established templates within the current chain to initiate your project. ',
  file: 'File',
  use_basic_template: 'Use basic template',
  use_public_template: 'Use public template',
  use_private_template: 'Use private template',
  or_import: 'Or import from',
  import_from_github: 'Import from github',
  import_from_gist: 'Import from gist',
  import_from_file: 'Import from file',
  private_template: 'Private template',
  save_private_template_tips:
    ' The current blockchain has not created a private template. Please save your project as a private template first.',
  template: 'Template',
  use_template: 'Ues this template',
  github_repo: 'GitHub repository',
  selected_branch: 'Selected branch',
  branch: 'Branch',
  gist_repo: 'Gist URL',
  upload_file: 'Upload the file',
  darg_file:
    'Drag and drop the file here or click on the button to upload the file',
  max: 'Max',
  reupload: 'Re-Upload',
  use_a_template: 'Use a template',
  set_as_default: 'Set as default',
  sandbox_pending: 'Pending...',
  sandbox_start_up: 'Start up sandbox',
  sandbox_shut_down: 'Shut down sandbox',
  modify_template: 'Modify template',
  open_project: 'Open project',
  no_search_result: 'There is no search result.',
  connect_git_import_repos: 'Connect Github to import repos',
  manage_imported_repos: 'Manage imported github repos',
  connect_git_import_gists: 'Connect Github to import gists',

  // plugins
  install: 'Install',
  uninstall: 'Uninstall',
  builtin: 'Built-in',
  search_plugin: 'Search here',
  hot_search: 'Hot search',
  chainide_plugin: 'ChainIDE Plugins',
  sort_by: 'Sort by',
  sort_default: 'Default',
  sort_rating: 'Rating',
  sort_installs: 'Installs',
  sort_published: 'Published date',
  sort_update: 'Updated date',
  sort_uses: 'Uses',

  // template
  chainide_template: 'ChainIDE Templates',
  search_template: 'Search templates',
  create: 'Create',
  no_desc: 'No desc',
  free: 'Free',
  template_default: 'DEFAULT',
  template_no_data: `<h2>Purpose and Target:</h2>
  <p>This blank template serves as a starting point for various projects. Its flexibility allows customization for different use cases and project scenarios.</p>
  <h2>Key Features:</h2>
  <ul>
  <li>Versatile support for various technology stacks and frameworks.</li>
  <li>Clean and minimalistic structure for easy adaptation.</li>
  <li>Scalable architecture for projects of different scales.</li>
  </ul>`,
  get_default_template_error: 'Failed to get default template',
  create_project_error: 'Failed to create project',

  // plugins detail
  overview: 'Overview',
  to_be_released: 'To Be Released',

  // message
  edit_message_success: 'Edit successful !',
  log_out_successful: 'Log out successful !',
  sign_in_message_success: 'Sign in successful !',
  refresh_token_expired_message:
    'Refresh token has expired please login again !',
  save_as_template_success: 'Save as template successful',
  delete_message_success: 'Delete successful !'
};
